.template-content {
  color: #425466;
  font-size: 12px;
  font-weight: 400;
  line-height: 120%;

  h2 {
    font-size: 14px;

    // &:first-child {
    //   color: #007EBF;
    // }
  }
}
