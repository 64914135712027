@import 'utils.scss';
@import 'table.scss';
@import 'template-content.scss';

.simulador {
  --b-color: #c7c7c7;

  ion-input {
    --padding-start: 0px;
    --padding-end: 10px;
    font-weight: 600;
    border-bottom: 1px solid var(--b-color);
  }

  &.ng-touched {
    &.ng-invalid {
      --b-color: var(--ion-color-danger);
    }

    &.ng-valid {
      --b-color: var(--ion-color-success);
    }
  }
}

div p:first-child {
  margin-top: 0;
}

.alert-custom {
  ion-icon {
    font-size: 62px;
    margin-bottom: 16px;

    &.error {
      color: var(--ion-color-danger-shade);
    }

    &.help {
      color: #24a2d7;
    }

    &.success {
      color: #2dd36f;
    }

    &.info {
      color: #54bbdd;
    }
  }

  ul {
    padding-left: 0;
    list-style: none;

    li {
      text-align: center;
      line-height: 1;
      padding: 5px 10px;
      border-radius: 10px;
      // background-color: var(--ion-color-light);
      margin-bottom: 5px;
      border: 1px solid var(--ion-color-success-shade);

      ion-label {
        font-size: 14px;
        color: var(--ion-color-dark);
      }
    }

    li:last-child {
      margin-bottom: 0px;
    }
  }

  ul.error {
    li {
      border-color: var(--ion-color-danger-shade);
    }
  }

  .body-help {
    color: #425466;

    p {
      margin: 0 0 16px 0;
      font-size: 14px;
    }

    h2 {
      margin: 0 0 16px 0;
      font-size: 15px;
      font-weight: 600;
    }
  }

  .body-info {
    color: #425466;

    p {
      margin: 0 0 16px 0;
      font-size: 14px;
    }

    h2 {
      color: #007ebf;
      margin: 0 0 16px 0;
      font-size: 15px;
      font-weight: 600;
    }
  }
}

.d-flex {
  display: flex;
}

.flex-row {
  flex-direction: row;
}

.flex-row-reverse {
  flex-direction: row-reverse;
}

ion-item.customInvalid {
  --full-highlight-height: 1px;
  --highlight-color-invalid: var(--ion-color-danger);
  --highlight-background: var(--highlight-color-invalid);
  --border-color: var(--highlight-color-invalid);

  [slot='error'] {
    display: block;
  }

  [slot='helper'] {
    display: none;
  }
}

ion-item.customValid.item-has-focus {
  --full-highlight-height: 1px;
  --highlight-color-valid: var(--ion-color-success);
  --highlight-background: var(--highlight-color-valid);
  --border-color: var(--highlight-color-valid);
}

ion-item.ion-invalid {
  [slot='error'] {
    display: none;
  }

  [slot='helper'] {
    display: block;
  }
}

ion-item.ion-invalid.ion-touched {
  --highlight-color-invalid: var(--ion-color-danger);
  --highlight-background: var(--highlight-color-invalid);
  --border-color: var(--highlight-color-invalid);

  [slot='error'] {
    display: block;
  }

  [slot='helper'] {
    display: none;
  }
}
